.date-filters {
    margin-bottom: 20px;
    display: flex;
    gap: 20px;
}

table {
    /* width: 100%; */
    border-collapse: collapse;
}

th, td {
    border: 1px solid #ccc;
    /* padding: 8px; */
    text-align: left;
    font-size: 12px;
}

th {
    cursor: pointer;
    background-color: #f9f9f9;
    text-align: center;
}

/* Второй столбец - ширина 40vw */
th:nth-child(2),
td:nth-child(2) {
    width: 35vw;
}

th:nth-child(10),
td:nth-child(10) {
    width: 100px !important;
}

/* Все остальные столбцы (кроме второго) - ширина 5vw */
th:nth-child(n):not(:nth-child(2)),
td:nth-child(n):not(:nth-child(2)) {
    width: 5vw;
    word-wrap: break-word;
}

th:hover {
    background-color: #f1f1f1;
}

td:nth-child(n+3) {
    text-align: center;
}

tr {
    height: 60px;
}

.highlighted-row {
    background-color: #f0f0f0; /* Светло-серый цвет фона */
    padding: 0;
}

.h-30 {
    height: 30px !important;
}

.container {
    display: flex;
    width: 100%;
    position: fixed;
    height: 100px;
}

.col:nth-child(2) {
    width: 40vw;
}

/* Применение ширины 5vw ко всем, кроме второго элемента */
.col:not(:nth-child(2)) {
    width: 5vw;
}

.col {
    padding: 8px;
    text-align: center;
    border: 1px solid #ddd;
    /* box-sizing: border-box; */
}

.sticky {
    position: fixed;
    top: 0;
  }

  .absolutly {
    position: absolute;
    top: 113px;
  }
